<template>
  <div class="book">
        <div :id="name" class="book-cover">
            <a :href="pdf" target="_blank">
                <img :src="image" alt="">
            </a>
        </div>
        <div class="book-name">
                {{name}}
                <br>
                ({{description}})
        </div>
  </div>
</template>

<script>
export default {
    components: {
    },
    props: [ 'image', 'name', 'pdf', 'description' ],
    data() {
        return {
            defaultOptions: Object,
            animController: Object
        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style>

    .book-name {
        padding: 0px;
        margin-top: 0px;
        text-align: center;
    }
    .book {
        margin: 8px;
        width: 120px;
        z-index: 1;
    }
    .book img {
        width: 100px;
        transition: transform .1s
    }
    .book img:hover {
        transform: scale(1.2)
    }

@media screen and (max-width: 600px) {
    .book {
        width: 80px;
    }
    .book img {
        width: 71px;
    }
}
</style>