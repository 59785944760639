<template>
    <div class="livroVermelho">
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 stripe-redbook text-center" title="Nome comum: Peixe Anjo; Crédito: Áthila Bertoncini; ">
                    <span class="tit1">Publicações</span><br />
                    <span class="tit2"></span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row text-left mt-4" >
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        <b>Livro Vermelho</b>
                        <br><br>
                        O Livro Vermelho da Fauna Brasileira Ameaçada de Extinção, publicado em 2018, apresentou o resultado do Processo de Avaliação coordenado pelo Instituto Chico Mendes e oficializado pelo Ministério do Meio Ambiente por meio das Portarias nº 444 e 445 de 2014. <br/><br/>

                        Foi resultado de um dos maiores esforços já empreendidos no mundo para avaliação de risco de extinção de espécies da fauna de um país, sendo avaliadas 12.254 espécies em 73 oficinas de trabalho com a participação de mais de 1.270 especialistas da comunidade científica brasileira e estrangeira, vindos de mais de 250 instituições.
                    </p>
                </div>
                <div class="col-md-6 col-12">
                        <p class="text-justify box">

                        ATENÇÃO!
                        <br>
A lista oficial de espécies ameaçadas da fauna brasileira foi atualizada em 2022, modificando parcialmente a relação de espécies desta obra.

O conteúdo deste livro vermelho corresponde à lista que esteve vigente entre 2014 e 2022. </p>

                </div>
            </div>

            <div class="book-list row d-flex align-items-center justify-content-center">
                <br>
                <Book :image="book.urlImage" :name='book.title' :description="book.description" :pdf="book.urlPdf" v-for="book in items.volumes" :key="book.title"/>
            </div>

        </div>
    </div>
</template>
<script>
import Book from '@/web/components/Book.vue'
import api from '@/services/api.js'

export default {
    components: {
        Book
    },
    data() {
        return {
            items: []
        }
    },
    created() {
        //var t = this
        api.get('/redBooks').then(response => (
            //t.items = response.data.data
            this.setItems(response.data.data)
        ))
    },
    methods: {
        setItems(pData){
            pData.volumes[0].description = 'Geral'
            this.items = pData
        },
    }
}
</script>

<style scoped>
    .text-justify{
        text-align: justify;
    }
    .book-list {
        margin-top: 2rem;
    }
    .stripe-redbook {
        background-image: url(/img/animals/lv-flip-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
    .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: white;
        border-top: 3px solid white;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: white;
    }
    .box{
        background-color:#F8D8D8;
        border: 2px solid red;
        border-radius: 10px;
        padding: 10px;
        font-weight: 900;
    }

@media screen and (max-width: 600px) {
    .book-list {
        margin-top: -1rem;
    }
    .stripe-redbook {
        height: 115px;
        padding-top: 40px;
    }
    .tit1 {
        font-size: 1.3rem;
        padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
}
</style>